import React, { useState } from "react";
import CustomButton, {
  BackButton,
  ButtonPosition,
} from "../components/buttons";
import { useNavigate } from "react-router-dom";
export default function Login() {
  const [mobile, setMobile] = useState();
  const navigate = useNavigate();
  return (
    <div className="container">
      <BackButton />
      <h1 className="mt-2">Login</h1>
      <p className="text-secondary text-sm">
        Welcome back! Please enter your phone number to access your account.
      </p>
      <input
        type="number"
        pattern="[0-9]*"
        value={mobile}
        onChange={(e) => setMobile(e.value)}
        maxLength={13}
        placeholder="Mobile Number"
      />
      <p className="text-sm">Don't have an account? Sign in</p>
      <ButtonPosition>
        <CustomButton
          label="Login"
          disable={mobile?.length < 10}
          onClick={() => navigate("/otp-verification")}
        />
      </ButtonPosition>
    </div>
  );
}
