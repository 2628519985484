import React, { useState } from "react";
import CustomButton, { BackButton, ToggleButton } from "../components/buttons";
import { ReactComponent as AcControlIcon } from "../assets/images/svg/ac-control.svg";
import { ReactComponent as HumidityIcon } from "../assets/images/svg/humidity.svg";
import { ReactComponent as AutoIcon } from "../assets/images/svg/auto.svg";
import { ReactComponent as SnowIcon } from "../assets/images/svg/snow.svg";
import { ReactComponent as HeatIcon } from "../assets/images/svg/heat.svg";

export default function AirConditioner() {
  const [temperature, setTemperature] = useState(24); // Default temperature 24°C
  const [mode, setMode] = useState("Cooling");
  const [isAcOn, setIsAcOn] = useState(false);

  const modeOptions = [
    {
      type: "Auto",
      icon: <AutoIcon />,
    },
    {
      type: "Cooling",
      icon: <SnowIcon />,
    },
    {
      type: "Heating",
      icon: <HeatIcon />,
    },
  ];

  return (
    <div className="container">
      <BackButton />
      <h1 className="mt-2">Air Conditioner</h1>
      <div className="flex gap-3">
        <div className="p-3 border-1 border-gray-200 border-round-2xl col m-0">
          <div className="flex align-items-center gap-2">
            <AcControlIcon className="mt-2" style={{ width: "6px", height: "20px" }} />
            <h1 className="my-0" style={{ fontSize: "36px" }}>
              {temperature}&deg; C
            </h1>
          </div>
          <div className="flex align-items-center gap-2  border-bottom-1 pb-4">
            <HumidityIcon />
            <p className="my-0">54%</p>
          </div>
          <p className="text-sm">Air conditioner is {isAcOn ? "ON" : "OFF"}</p>
          <ToggleButton checked={isAcOn} onChange={(value)=> setIsAcOn(prev=> !prev)}/>
        </div>
        <div className="flex flex-column justify-content-between ac-modes">
          {modeOptions?.map((option, index) => (
            <span
              key={index}
              className={`p-3 flex gap-2 border-round-2xl border-1 cursor-pointer ${
                mode === option?.type && "selected-mode"
              }`}
              onClick={() => setMode(option?.type)}
            >
              {option?.icon}
              {option?.type}
            </span>
          ))}
        </div>
        <div>
          <div className="temprature-range">
            <input
              type="range"
              max={32}
              min={16}
              value={temperature}
              onChange={(e) => setTemperature(e?.target?.value)}
              step={1}
            />
            <span className="absolute inline-block text-center w-full">{temperature}&deg;</span>
          </div>
        </div>
      </div>
    </div>
  );
}
