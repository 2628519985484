import React, { useState } from 'react'
import Navigation from '../components/Navigation';
import {ReactComponent as DiningIcon} from "../assets/images/svg/dining.svg";
import {ReactComponent as EventsIcon} from "../assets/images/svg/events.svg";
import {ReactComponent as FitnessIcon} from "../assets/images/svg/fitness.svg";
import {ReactComponent as SpaIcon} from "../assets/images/svg/spa.svg";
import { OptionCard } from '../components/cards';

export default function Facilities() {
  const [selectedFacility, setSelectedFacility] = useState("Dining");
  const facilityOptions = [
    {
      label: "Dining",
      icon: <DiningIcon/>
    },
    {
      label: "Events",
      icon: <EventsIcon/>
    },
    {
      label: "Fitness",
      icon: <FitnessIcon/>
    },
    {
      label: "Spa",
      icon: <SpaIcon/>
    },
  ]
  return (
    <div className="container">
    <p className="text-secondary font-italic">Welcome,</p>
    <h2 className="my-2 text-3xl">James Miller 👋🏻</h2>
    <p className="text-secondary text-sm">Room 208, smart management</p>
    <OptionCard data={facilityOptions} selected={selectedFacility} setSelected={setSelectedFacility}/>
    <Navigation/>
  </div>);
  
}
