import React from "react";
import CustomButton, { BackButton, ButtonPosition } from "../components/buttons";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ReceptionImage } from "../assets/images/svg/reception-screen.svg";


export default function Reception() {
  const navigate = useNavigate();
  return (
    <div className="container">
      <BackButton />
      <h1 className="mt-2">Contact the Hotel Receptionist</h1>
      <p className="text-secondary">
        We hope you're enjoying your stay with us! Our team is dedicated to
        ensuring your comfort and satisfaction throughout your time here. If you
        have any questions, doubts, or require assistance with anything at all,
        please don't hesitate to reach out to us
      </p>
      <div className="flex justify-content-center pt-7">
      <ReceptionImage/></div>
      <ButtonPosition>
        <CustomButton
          label={<span>Continue <i className="pi pi-arrow-right"/></span>}
          onClick={() => navigate("/chat")}
        />
      </ButtonPosition>
    </div>
  );
}
