import React, { useEffect, useState } from "react";
import { BackButton, ToggleButton } from "../components/buttons";
import { ReactComponent as SwitchOn } from "../assets/images/svg/switch-on.svg";
import { ReactComponent as LightCardIcon } from "../assets/images/svg/light-card.svg";
import { LightControlCard } from "../components/cards";

export default function Lights() {
    const [masterSwitch, setMasterSwitch] = useState(false);
  const [lights, setLights] = useState([
    {
      label: "Smart Light 1",
      isOn: false,
    },
    {
      label: "Smart Light 2",
      isOn: true,
    },
    {
      label: "Smart Light 3",
      isOn: false,
    },
    {
      label: "Smart Light 4",
      isOn: false,
    },
  ]);

  const handleLightOff=({id, value})=>{
    console.log('id, value', id, value)
    setLights(prev=> prev?.map((light, index)=> index===id ? {...light, isOn: value} : light))
  }

  useEffect(()=>{
    setLights(prev=> prev.map(light=> ({...light, isOn: masterSwitch})))
  }, [masterSwitch])


  console.log('lights', lights)
  return (
    <div className="container">
      <BackButton />
      <h1 className="mt-2">Lights Control</h1>
      <div
        className="p-2 my-4 border-1 border-gray-200 flex justify-content-between align-items-center"
        style={{ borderRadius: "44px" }}
      >
        <div>
          <p className="text-sm m-1">Master Switch</p>
          <p className="text-sm text-secondary m-1">
            Turn {masterSwitch ? "off" : "on"} master switch, to turn {masterSwitch ? "off" : "on"} all the lights.
          </p>
        </div>
        <ToggleButton IconChecked={SwitchOn} extraClass="circle-yellow" checked={masterSwitch} onChange={({value})=> setMasterSwitch(value)} />
      </div>
        <div className="flex flex-wrap">
      {lights?.map((light, index) => (
        <LightControlCard
          Icon={LightCardIcon}
          key={index}
          id={index}
          label={light.label}
          isOn={light.isOn}
          onChange={handleLightOff}
        />
      ))}</div>
    </div>
  );
}
