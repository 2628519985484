import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import Navigation from "../components/Navigation";
import { ToggleButton } from "../components/buttons";
import { ReactComponent as MasterScenesIcon } from "../assets/images/svg/master-scene.svg";
import { ReactComponent as NightScenesIcon } from "../assets/images/svg/night-scene.svg";
import { ReactComponent as MovieScenesIcon } from "../assets/images/svg/movie-scene.svg";
import { ReactComponent as ServiceRequestIcon } from "../assets/images/svg/service-request.svg";
import { ReactComponent as LightControlIcon } from "../assets/images/svg/light-control.svg";
import { ReactComponent as AcControlIcon } from "../assets/images/svg/ac-control.svg";
import { ReactComponent as FoodOrderIcon } from "../assets/images/svg/food-order.svg";
import { ReactComponent as ArrowCircleIcon } from "../assets/images/svg/arrow-circle.svg";
import { ReactComponent as LockIcon } from "../assets/images/svg/lock.svg";

export default function Home() {
  const [isDoorsClosed, setIsDoorsClosed] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) navigate("/on-boarding");
  }, [navigate]);

  const roomScenes = [
    {
      label: "Master Scenes",
      icon: <MasterScenesIcon />,
    },
    {
      label: "Night Scenes",
      icon: <NightScenesIcon />,
    },
    {
      label: "Movie Scenes",
      icon: <MovieScenesIcon />,
    },
  ];

  const callOptions = [
    {
      label: "Call Reception",
      description: "Need help? Call reception!",
      icon: "☎️",
    },
    {
      label: "Tea",
      description: "Need a break? Tea is coming!",
      icon: "🍵",
    },
    {
      label: "Snack",
      description: "Peckish? Snack time!",
      icon: "🍪",
    },
  ];

  const quickOptions = [
    {
      label: "Service Request",
      description: <p className="text-secondary text-xs my-0">From 8:00 am - 11: pm</p>,
      icon: <ServiceRequestIcon/>,
      link: "/services"
    },
    {
      label: "Light Control",
      description: <div className="flex align-items-center gap-3"><ToggleButton/><p className="text-secondary text-xs my-0">Master Switch</p></div>,
      icon: <LightControlIcon/>,
      link: "/light-control"
    },
    {
      label: "Air Conditioner",
      description: <div className="flex align-items-center gap-3"><ToggleButton/><p className="text-secondary text-xs my-0">Master Switch</p></div>,
      icon: <AcControlIcon/>,
      link: "/ac-control"
    },
    {
      label: "Food Order",
      description: <p className="text-secondary text-xs my-0">From 8:00 am - 11: pm</p>,
      icon: <FoodOrderIcon/>,
      link: "/food-order"
    },
  ];

  return (
    <div className="container">
      <p className="text-secondary font-italic">Welcome,</p>
      <h2 className="my-2 text-3xl">James Miller 👋🏻</h2>
      <p className="text-secondary text-sm">Room 208, smart management</p>
      <div
        className="p-2 my-4 border-1 border-gray-200 flex justify-content-between align-items-center"
        style={{ borderRadius: "44px" }}
      >
        <div>
          <p className="text-sm m-1">Doors are {isDoorsClosed ? "closed" : "open"}</p>
          <p className="text-sm text-secondary m-1">
            Switch to open or close the doors
          </p>
        </div>
        <ToggleButton IconChecked={LockIcon} extraClass="circle-blue" checked={isDoorsClosed} onChange={({value})=> setIsDoorsClosed(value)} />
      </div>
      <p>
        <span className="text-secondary">Room </span>
        <span className="font-italic">Scenes</span>
      </p>
      <div>
        {roomScenes?.map((scene, index) => (
          <div
            key={index}
            className="flex justify-content-between align-items-center p-3 mb-3 border-round-xl  bg-gray"
          >
            <div className="flex justify-content-between align-items-center gap-3">
              {scene.icon}
              <p className="text-sm">{scene.label}</p>
            </div>
            <ToggleButton />
          </div>
        ))}
      </div>
      <p>
        <span className="text-secondary">Quick </span>
        <span className="font-italic">Calls</span>
      </p>
      <div className="flex gap-3 pb-3">
        {callOptions?.map((option, index) => (
          <div className="text-center p-2 border-round-xl col bg-gray">
            {option.icon}
            <p className="text-sm mb-0">{option.label}</p>
            <p className="text-xs text-secondar my-1">{option.description}</p>
          </div>
        ))}
      </div>
      <p>
        <span className="text-secondary">Quick </span>
        <span className="font-italic">Actions</span>
      </p>  
      <div className="flex pb-7 flex-wrap">
        {quickOptions?.map((option, index) => (
          <div className="col-6 align-items-stretch flex">
          <div key={index}className={`p-3 border-round-3xl border-gradient w-full cursor-pointer bg-gray gradient-${index+1}`}>
            <div className="flex justify-content-between align-items-center">{option.icon}<ArrowCircleIcon  onClick={()=> navigate(option.link)} /></div>
            <p className="text-sm max-w-min">{option.label}</p>
            <p className="text-xs text-secondar my-1">{option.description}</p>
          </div>
          </div>
        ))}
      </div>
      <Navigation />
    </div>
  );
}
