import React from "react";
import CustomButton, { CountButton, ToggleButton } from "./buttons";

export const LightControlCard = ({
  Icon,
  label,
  id,
  isOn,
  subTitle = "Power Off",
  extraClass = "",
  gradientClass = "",
  onChange,
}) => {
  return (
    <div className="col-6 align-items-stretch flex">
      <div
        className={`light-control-card p-3 pt-0 border-round-3xl border-gradient w-full cursor-pointer bg-gray ${
          isOn && "gradient-" + (id + 1)
        }  ${gradientClass} ${extraClass}`}
      >
        <div className="flex justify-content-center">
          <Icon />
        </div>
        <p className="text-sm">{label}</p>
        <div className="flex justify-content-between">
          <p className="text-xs text-secondar my-1">{subTitle}</p>
          <ToggleButton
            checked={isOn}
            onChange={({ value }) => onChange({ id, value })}
          />
        </div>
      </div>
    </div>
  );
};

export const OptionCard = ({
  data,
  selected,
  setSelected,
  extraClass = "",
}) => {
  return (
    <div className="flex gap-3 align-items-center overflow-auto options-card">
      {data?.map((option, index) => (
        <span
          className={`p-2 border-round-2xl flex align-items-center cursor-pointer gap-2 extraClass ${
            option.label === selected ? "selected-option" : "bg-gray"
          }`}
          onClick={() => setSelected(option?.label)}
        >
          {option?.icon && option?.icon}
          {option?.label}
        </span>
      ))}
    </div>
  );
};

export const FoodCard = ({
  data,
  cartItems,
  handleAdd,
  handleRemove,
  onClick,
}) => {
  return data?.map((food, index) => (
    <div className="flex border-round-2xl border-1 my-3" key={index}>
      <div className="col">
        <p className="text-white m-0">{food?.name}</p>
        <p className="m-1 text-secondary text-sm">{food?.description}</p>
        <p className="m-1 text-secondary text-sm">{food?.energy}</p>
        <div className="flex justify-content-between">
          <p className="text-yellow">&#8377; {food?.price}</p>
          {cartItems &&
          cartItems?.find((cart) => cart?.id === food?.id)?.length ? (
            <CountButton
              handleAdd={handleAdd}
              handleRemove={handleRemove}
              count={cartItems.find((cart) => cart?.id === food?.id).qtn}
            />
          ) : (
            <CustomButton secondary label="Add" />
          )}
        </div>
      </div>
      <div className="col-3 border-round-2xl p-0">
        <img src={food?.image} alt={food?.name} className="w-full h-full"/>
      </div>
    </div>
  ));
};
