import React, { useEffect, useState } from 'react'
import CustomButton, { BackButton, ButtonPosition } from "../components/buttons";
import { useNavigate } from "react-router-dom";
import { InputOtp } from 'primereact/inputotp';
export default function Verification() {
    let [counter, setCounter] = useState(60);
    const [token, setTokens] = useState();
    const navigate = useNavigate();

   
    useEffect(() => {
        const countDown = ()=> {
            setCounter(prev=> prev > 0 ? prev-1 : prev);
        }
        let interval = setInterval(countDown, 1000)
      return () => {
        clearInterval(interval)
      }
    }, [setCounter])
    
    return (
      <div className="container">
        <BackButton />
        <h1 className="mt-2">6-digit code</h1>
        <p className="text-secondary text-sm">
        Code sent to +91 9999999999 unless you already have an account
        </p>
        <InputOtp value={token} onChange={(e) => setTokens(e.value)} integerOnly length={6}/>
        <p className="text-sm text-secondary"><span onClick={()=> !counter ? window.location.reload() : null}>Resend code</span> {counter ? `in 00:${counter}` : ""}</p>
        <p className="text-sm">Already have an account? Log in </p>
        <ButtonPosition>
            <CustomButton label="Verify" onClick={()=> navigate("/home")} disable={token?.length <6}/>
          </ButtonPosition>
      </div>
    );
  }
  