import React from 'react'
import "./App.scss"
import { HashRouter, Route, Routes } from 'react-router-dom'
import { ProtectedRoute } from './routes'
import { PrivateRoutes } from './routes/routes'
import OnBoarding from './pages/OnBoarding'
import Home from './views/Home'
import Login from './pages/Login'
import Verification from "./pages/Verification"
import Reception from './views/Reception'
import Facilities from './views/Facilities'
import Services from './views/Services'
import Chat from './views/Chat'
import Lights from './views/Lights'
import AirConditioner from './views/AirConditioner'
import FoodOrder from './views/FoodOrder'
export default function App() {
  return (
    <HashRouter>
      <Routes>
      <Route path="/" element={<OnBoarding />} />
      <Route path="/login" element={<Login />} />
      <Route path="/otp-verification" element={<Verification />} />
      <Route path="/home" element={<Home />} />
      <Route path="/reception" element={<Reception />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/facilities" element={<Facilities />} />
      <Route path="/services" element={<Services />} />
      <Route path="/light-control" element={<Lights />} />
      <Route path="/ac-control" element={<AirConditioner />} />
      <Route path="/food-order" element={<FoodOrder />} />
      <Route element={<ProtectedRoute />}>
                            {PrivateRoutes?.map((route, key) => (
                                <Route key={key} path={route.path} element={route.element} exact={route.exact}>
                                    {/* {route.items?.map((item, index) => (
                                        <Route key={index} path={item.path} element={item.element} exact={item.exact} />
                                    ))} */}
                                </Route>
                            ))}
                        </Route>
      </Routes>
    </HashRouter>
  )
}
