import React from "react";
import { BackButton } from "../components/buttons";
import { FoodCard } from "../components/cards";
import FoodImg from "../assets/images/png/food-1.png";

export default function FoodOrder() {
  const foodData = [
    {
      name: "Cheesy Chicken Burger",
      description:
        "A classic favorite, our chicken burger features a juicy, grilled or... read more",
      energy: "200 Kcal",
      price: 250,
      image: FoodImg
    },
    {
      name: "Chicken lollipop",
      description:
        "A classic favorite, our chicken burger features a juicy, grilled or... read more",
      energy: "200 Kcal",
      price: 250,
      image: FoodImg
    },
    {
      name: "Chicken Pizza",
      description:
        "A classic favorite, our chicken burger features a juicy, grilled or... read more",
      energy: "200 Kcal",
      price: 250,
      image: FoodImg
    },
    {
      name: "Rice'n Paneer",
      description:
        "A classic favorite, our chicken burger features a juicy, grilled or... read more",
      energy: "200 Kcal",
      price: 250,
      image: FoodImg  
    },
  ];

  return (
    <div className="container">
      <BackButton />
      <h1 className="mt-2 mb-0">Food Order</h1>
      <p className="mt-1 text-secondary text-sm">Room 208</p>
      <div className="border-1 flex align-items-center border-round-3xl">
        <i className="pi pi-search m-3" />
        <input
          className="m-0 bg-transparent"
          placeholder="Search for favourite food"
        />
      </div>
      <FoodCard data={foodData} cartItems={[]} />
    </div>
  );
}
