import React from "react";
import CustomButton from "../components/buttons";
import OnBoardingImg from "../assets/images/png/on-boarding.png";
import { useNavigate } from "react-router-dom";
export default function OnBoarding() {

  const navigate = useNavigate();

  return (
    <div className="onboarding-container">
      <div className="img-container">
        {/* <OnBoardingImg /> */}
        <img src={OnBoardingImg} alt='On-boarding'/>
      </div>
      <div className="fixed max-w-700 bottom-0 bg-black">
      <p className="text-lg text-center m-2 py-6">
        Welcome! Take control of your stay with easy room adjustments, meal
        orders, staff chats, and service requests—all at your fingertips. Let's
        make your experience exceptional
      </p>
      <div className="flex justify-content-center pb-6"><CustomButton label="Login" onClick={()=> navigate("/login")}/></div></div>
    </div>
  );
}
