import React from 'react'
import { BackButton } from '../components/buttons'

export default function Services() {
  return (
    <div className="container">
    <BackButton />
    <h1 className="mt-2">Service Request</h1></div>
  )
}
