import React from "react";
import { ReactComponent as HomeIcon } from "../assets/images/svg/home.svg";
import { ReactComponent as HomeActiveIcon } from "../assets/images/svg/home-active.svg";
import { ReactComponent as ReceptionIcon } from "../assets/images/svg/reception.svg";
import { ReactComponent as FacilitiesIcon } from "../assets/images/svg/facilities.svg";
import { ReactComponent as FacilitiesActiveIcon } from "../assets/images/svg/facilities-active.svg";
import { ReactComponent as ServicesIcon } from "../assets/images/svg/services.svg";
import { NavLink } from "react-router-dom";

export default function Navigation() {
  const navItems = [
    {
      label: "Home",
      icon: <HomeIcon />,
      activeIcon: <HomeActiveIcon />,
      link: "/home",
    },
    {
      label: "Reception",
      icon: <ReceptionIcon />,
      activeIcon: null,
      link: "/reception",
    },
    {
      label: "Facilities",
      icon: <FacilitiesIcon />,
      activeIcon: <FacilitiesActiveIcon />,
      link: "/facilities",
    },
    {
      label: "Services",
      icon: <ServicesIcon />,
      activeIcon: null,
      link: "/services",
    },
  ];
  return (
    <div className="fixed bottom-0 bg-gray w-full left-0 overflow-hidden">
      <div className="max-w-700 flex justify-content-around ">
        {navItems?.map((menu, index) => (
          <NavLink to={menu?.link} className="nav-link no-underline">
            {({ isActive }) => (
              <span
                className={`flex flex-column align-items-center p-3 ${
                  isActive ? "active text-light" : "text-secondary"
                }`}
              >
                {isActive ? menu?.activeIcon : menu?.icon}
                <span>{menu.label}</span>
              </span>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
}
